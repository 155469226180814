import { BrowserModule } from '@angular/platform-browser';
import { NgModule, provideExperimentalZonelessChangeDetection } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DevelopmentModule } from './development/development.module';
import { HistoryModule } from './history/history.module';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AuthButtonComponent } from './auth-button/auth-button.component';
import { AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { environment } from 'src/environments/environment';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';

import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { HomeComponent } from './home/home.component';

import { RippleModule } from 'primeng/ripple';

@NgModule({
	declarations: [
		AppComponent,
		AuthButtonComponent,
		UserProfileComponent,
		HomeComponent
	],
	exports: [
			ToastModule
	],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		CommonModule,
		DevelopmentModule,
		HistoryModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		// HttpClientModule,
		ApolloModule,
		LoadingBarHttpClientModule,
		LoadingBarRouterModule,
		InputTextModule,
		ToastModule,
		RippleModule,
		// Import the module into the application, with configuration
		AuthModule.forRoot({
			domain: environment.auth0.domain,
			clientId: environment.auth0.clientId,
			cacheLocation: 'localstorage',
			authorizationParams: {
				redirect_uri: window.location.origin,
				// Request this audience at user authentication time
				audience: environment.auth0.audience,
			},
			// Specify configuration for the interceptor
			httpInterceptor: {
				allowedList: [
					{
						// Match any request that starts 'https://api.scoperoot.com' (note the asterisk)
						uri: environment.graphQLAPI.domain + '*',
						tokenOptions: {
							authorizationParams: {
								// The attached token should target this audience
								audience: environment.graphQLAPI.domain,
							}
						}
					}
				]
			}
		})
	],
	providers: [
		MessageService,
		// https://medium.com/@coreboarder/adieu-zone-js-angular-18-zoneless-applications-b5ca4d6f727f - Toodles Zone.js by adding the below
		provideExperimentalZonelessChangeDetection(),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthHttpInterceptor,
			multi: true
		},
		{
			provide: APOLLO_OPTIONS,
			useFactory: (httpLink: HttpLink, auth: AuthService) => {
				// const domain = "api.scoperoot.com";
				// const token = auth.getAccessTokenWithPopup({
				// 	audience: `https://${domain}`,
				// }).subscribe((data) => {
				// 	console.log(data);
				// });
				return {
					cache: new InMemoryCache(),
					// TODO Need to remove this so that we can use the apollo cache. Figure out how to invalidate the cache on a case by case basis
					// instead. This is needed on values-page initScope().
					defaultOptions: {
						watchQuery: {
							fetchPolicy: 'network-only',
							errorPolicy: 'all',
						}
					},
					link: httpLink.create({
						uri: environment.graphQLAPI.domain + environment.graphQLAPI.endpoint,
						// headers: {

						// }
					})
				};
			},
			deps: [HttpLink, AuthService]
		},
		provideHttpClient(withInterceptorsFromDi())
	],
})
export class AppModule { }
