import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { ScopeVariablesCountQuery, ScopeVariablesCountQueryVariables } from 'src/generated/graphql';
import { Subscription } from 'rxjs';
import { CommonService } from '../app-common/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

	private isLoading = false;
	private scopeVariablesCountQuery?: QueryRef<ScopeVariablesCountQuery, ScopeVariablesCountQueryVariables>;
	private scopeVariablesSubscription?: Subscription;
	constructor(private router: Router, private apollo: Apollo, private commonService: CommonService) {}

	ngOnInit(): void {
		this.scopeVariablesCountQuery = this.apollo.watchQuery({
			query: gql`
				query scopeVariablesCount {
					scopeVariablesAggregate {
						aggregate {
							count
						}
					}
				}
			`,
			variables: {},
		});

		this.isLoading = true;
		this.scopeVariablesSubscription?.unsubscribe();
		this.scopeVariablesSubscription = this.scopeVariablesCountQuery.valueChanges.subscribe({
			next: ({ data, loading }) => {
				this.isLoading = false;

				if (data.scopeVariablesAggregate.aggregate.count === 0) {
					this.router.navigate(['/scope-variables']);
				}
				else {
					this.router.navigate(['/scope-snapshot']);
				}
			},
			error: (error) => {
				this.isLoading = false;
				this.commonService.queryErrorHandler(error);
			}
		});
	}

	ngOnDestroy(): void {
		this.scopeVariablesSubscription?.unsubscribe();
	}
}
