import { Component, OnDestroy } from '@angular/core';
import { Apollo, QueryRef, gql } from 'apollo-angular';
import { CommonService } from 'src/app/app-common/common.service';
import { GqlRequestInfo, gqlRequestInfo } from 'src/app/crud-state.service';
import { HistoryPageQuery, HistoryPageQueryVariables } from 'src/generated/graphql';
import { CrudStateService } from 'src/app/crud-state.service';
import _ from 'lodash';
import { FormControl, Validators } from '@angular/forms';

type ComponentGqlRequestInfos = {
	queries: {
		historyPage: GqlRequestInfo<HistoryPageQuery, HistoryPageQueryVariables>,
	}
};

type ColumnHeading = {
	column: keyof Omit<HistoryPageQuery['logs'][number], "__typename">
	label: string,
	isFirstUniqueColumnWithinTransaction?: boolean,
};

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss']
})
export class HistoryPageComponent implements OnDestroy {
	_ = _;

	gqlRequestInfos: ComponentGqlRequestInfos = {
		queries: {
			historyPage: gqlRequestInfo(gql`
				query historyPage($offset: Int!, $limit: Int!) {
					logs(offset: $offset, limit: $limit, orderBy: {actionTstampTx: DESC}) {
						id
						actionTstampTx
						action
						changedFields
						clientAddr
						rowData
						tableName
						tenantId
						userId
						user {
							id
							name
							email
						}
						transactionId
					}
					logsAggregate {
						aggregate {
							count
						}
					}
				}
			`),
		},
	};

	private historyPageQuery?: QueryRef<HistoryPageQuery, HistoryPageQueryVariables>;

	historyPageData?: HistoryPageQuery;
	columnHeadings: ColumnHeading[] =
	[
		{
			column: 'transactionId',
			label: 'Transaction ID',
		},
		{
			column: 'actionTstampTx',
			label: 'Timestamp',
		},
		{
			column: 'user',
			label: 'User',
		},
		{
			column: 'tableName',
			label: 'Table Name',
			isFirstUniqueColumnWithinTransaction: true,
		},
		{
			column: 'action',
			label: 'Action',
		},
		{
			column: 'rowData',
			label: 'Row Data',
		},
		{
			column: 'changedFields',
			label: 'Changed Fields',
		}
	]
	isLoading = false;

	pageNumber = 0;
	// FYI - This page number input is 1-indexed (for UI purposes), but the pageNumber variable is 0-indexed.
	pageNumberInput: FormControl<number> = new FormControl<number>(
		this.pageNumber + 1,
		{
			validators: [
				Validators.min(1),
				Validators.required,
				(control) => {
					const numberOfPages = this.safeNumberOfPages();
					if (numberOfPages !== null && control.value > numberOfPages) {
						return {"outOfBounds" : `Page number must be between 1 and ${numberOfPages}.`}
					}
					return null;
				}
			],
			nonNullable: true
		});

	pageSize = 100;

	actionMapping = {
		'D': 'Delete',
		'U': 'Update',
		'I': 'Insert',
	};

	constructor(private apollo: Apollo, private commonService: CommonService) { }

	ngOnInit(): void {
		const scopesQueryInfo = this.gqlRequestInfos.queries.historyPage;
		scopesQueryInfo.subscription?.unsubscribe();

		this.historyPageQuery = this.apollo.watchQuery({
			query: this.gqlRequestInfos.queries.historyPage.gql,
			variables: {
				offset: (this.pageNumberInput.value - 1) * this.pageSize,
				limit: this.pageSize,
			},
		});

		this.isLoading = true;

		this.gqlRequestInfos.queries.historyPage.subscription = this.historyPageQuery.valueChanges.subscribe({
			next: ({ data, loading }) => {
				this.isLoading = false;

				if (data) {
					this.historyPageData = data;
					this.pageNumber = this.pageNumberInput.value - 1;
				}
			},
			error: (error) => {
				this.isLoading = false;
				this.commonService.queryErrorHandler(error);
			}
		});

	}

	requireHistoryPageData(): HistoryPageQuery {
		if (this.historyPageData === undefined) {
			throw new Error('requireHistoryPageData() called when this.historyPageData is undefined.');
		}
		return this.historyPageData;
	}

	gridTemplateColumns(): string {
		return this.columnHeadings.map((columnAndLabel) => {
			if (['rowData'].includes(columnAndLabel.column)) {
				return `[${columnAndLabel.column}] minmax(max-content, auto)`;
			}
			else if (['changedFields'].includes(columnAndLabel.column)) {
				return `[${columnAndLabel.column}] minmax(max-content, auto)`;
			}
			else {
				return `[${columnAndLabel.column}] minmax(max-content, auto)`;
			}
		}).join(' ') + ' [end]';
	}

	firstColumnHeading(): ColumnHeading {
		const firstColumnHeading = this.columnHeadings[0];
		if (firstColumnHeading === undefined) {
			throw new Error('firstColumnHeading() called when there are no visible columns.');
		}
		return firstColumnHeading;
	}

	firstUniqueColumnHeadingWithinTransaction(): ColumnHeading {
		const firstUniqueColumnHeadingWithinTransaction = this.columnHeadings.find((columnHeading) => columnHeading.isFirstUniqueColumnWithinTransaction);
		if (firstUniqueColumnHeadingWithinTransaction === undefined) {
			throw new Error('Could not find the firstUniqueColumnHeadingWithinTransaction().');
		}
		return firstUniqueColumnHeadingWithinTransaction;
	}

	isCellRedundant(row: HistoryPageQuery['logs'][number], column: keyof Omit<HistoryPageQuery['logs'][number], "__typename">): boolean {
		if (column === 'transactionId' || column === 'actionTstampTx' || column === 'user') {
			if (this.isSameTransactionAsPreviousOrNextRow(row, -1)) {
				return true;
			}
		}
		return false;
	}

	isSameTransactionAsPreviousOrNextRow(row: HistoryPageQuery['logs'][number], previousOrNextOffset: number): boolean {
		const historyPageData = this.requireHistoryPageData();
		const indexOfCurrentRow = historyPageData.logs.findIndex((currRow) => currRow.id === row.id);
		const previousRow = historyPageData.logs[indexOfCurrentRow + previousOrNextOffset];
		if (previousRow === undefined) {
			return false;
		}
		return previousRow.transactionId === row.transactionId;
	}

	numberOfPages(): number {
		const historyPageData = this.requireHistoryPageData();
		const numberOfEventsToPaginate = historyPageData.logsAggregate.aggregate.count;
		if (numberOfEventsToPaginate === undefined) {
			throw new Error("Could not determine the number of flags to paginate.");
		}
		return Math.max(1, Math.ceil(numberOfEventsToPaginate / this.pageSize));
	}

	private safeNumberOfPages(): number | null {
		try {
			return this.numberOfPages();
		}
		catch (e) {
			return null;
		}
	}

	visiblePageNumber(): number {
		return this.pageNumber + 1;
	}

	goToPage(newPageNumber: number) {
		// FYI page number is 0-indexed, and newPageNumber is also expected to be 0-indexed.
		if (newPageNumber === this.pageNumber) {
			return;
		}
		if (newPageNumber < 0 || newPageNumber >= this.numberOfPages()) {
			throw new Error(`Invalid page number ${newPageNumber}`);
		}
		// this.pageNumber = newPageNumber;
		this.pageNumberInput.setValue(newPageNumber + 1);
		if (this.historyPageQuery === undefined) {
			throw new Error('this.historyPageQuery is undefined.');
		}
		this.isLoading = true;
		this.historyPageQuery.refetch({
			offset: newPageNumber * this.pageSize,
			limit: this.pageSize,
		});
	}
	ngOnDestroy(): void {
		CrudStateService.unsubscribeFromGqlSubscriptions(this.gqlRequestInfos);
	}
}
