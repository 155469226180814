import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HistoryPageComponent } from './history/history-page/history-page.component';
import { HomeComponent } from './home/home.component';
// import { ConfigPageComponent } from './development/config-page/config-page.component';


const routes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: '', loadChildren: () => import('./development/development.module').then(m => m.DevelopmentModule) },
	{ path: '', loadChildren: () => import('./scope/scope.module').then(m => m.ScopeModule) },
	{ path: '', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
	{ path: 'history', component: HistoryPageComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
