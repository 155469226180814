<div class="user-profile" *ngIf="auth.user$ | async as user">
	<img
		[src]="user.picture"
		alt="User's profile picture"
		class="rounded-circle img-fluid profile-picture"
	>
	<div class="user-details">
		<div class="user-name" *ngIf="user.name !== user.email">{{ user.name }}</div>
		<div class="user-email">{{ user.email }}</div>
		<a class="action-link logout on-dark-background" (click)="auth.logout()">Logout</a>
	</div>
	<!-- <li><pre>{{ user | json }}</pre></li> -->
	<!-- <li *ngIf="auth.getAccessTokenSilently() | async as tokenclaim">{{ tokenclaim | json }}</li> -->
</div>
