import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryPageComponent } from './history-page/history-page.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RippleModule } from 'primeng/ripple';


@NgModule({
  declarations: [
    HistoryPageComponent
  ],
  imports: [
    CommonModule,
		NgxJsonViewerModule,
		InputNumberModule,
		FormsModule,
		ReactiveFormsModule,
		RippleModule,
  ]
})
export class HistoryModule { }
