import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ScopeSnapshotPageComponent } from './development/scope-snapshot-page/scope-snapshot-page.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'admin';

	constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService, public loader: LoadingBarService, private primengConfig: PrimeNGConfig, public activatedRoute: ActivatedRoute) {
		this.primengConfig.ripple = true;

		auth.isAuthenticated$.subscribe((isAuthenticated) => {
			if (!isAuthenticated) {
				const redirectBackToUrl = window.location.pathname + window.location.search + window.location.hash;
				auth.loginWithRedirect({
					appState: { target: redirectBackToUrl }
				});
			}
		});
		auth.isLoading$.subscribe((isLoading) => {
			const loadingBarState = loader.useRef('auth');
			if (!isLoading) {
				loadingBarState.complete();
			}
			else {
				loadingBarState.start();
			}
		});
	}

	isScopeSnapshotPage(): boolean {
		return this.activatedRoute.snapshot.firstChild?.routeConfig?.component === ScopeSnapshotPageComponent;
	}
}
