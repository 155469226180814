import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {

	constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService) {

		auth.user$.subscribe((data) => {
			console.log(data);
		})
	}
}
