<ng-container *ngIf="historyPageData as data">
	<div class="history-page-container">
		<div class="section-title">{{data.logsAggregate.aggregate.count}} Event{{data.logsAggregate.aggregate.count !== 1 ? 's' : ''}}</div>
		<div class="grid-based-table" [style]='{
			"grid-template-columns": gridTemplateColumns()
		}'>
			<div class="disabler-overlay" *ngIf="isLoading"></div>
			<div class="headings-background" [style]='{
				"grid-column-start": firstColumnHeading().column
			}'></div>
			<div
				class="heading {{columnAndLabel.column}}"
				[style]="{
					'grid-column-start': columnAndLabel.column
				}"
				*ngFor="let columnAndLabel of columnHeadings"
			>
				{{columnAndLabel.label}}
			</div>

			<div
				class="row"
				*ngFor="let log of data.logs; let index = index">

				<ng-container
					*ngFor="let columnAndLabel of columnHeadings"
				>
					<div
						class="cell"
						[style]="{
							'grid-column-start': columnAndLabel.column
						}"
						[class]="{
							'redundant-cell': isCellRedundant(log, columnAndLabel.column)
						}"
						[ngSwitch]="columnAndLabel.column"
					>
						<div class="left-border" *ngIf="(isSameTransactionAsPreviousOrNextRow(log, -1) || isSameTransactionAsPreviousOrNextRow(log, 1)) && firstUniqueColumnHeadingWithinTransaction().column === columnAndLabel.column"></div>
						<div class="cell-inner" *ngSwitchCase="'transactionId'">
							{{log[columnAndLabel.column] }}
						</div>
						<div class="cell-inner" *ngSwitchCase="'actionTstampTx'">
							{{log[columnAndLabel.column] | date: 'medium'}}
						</div>
						<div class="cell-inner" *ngSwitchCase="'changedFields'">
							<ngx-json-viewer [json]="log[columnAndLabel.column]" [depth]="0" *ngIf="log[columnAndLabel.column] !== null"></ngx-json-viewer>
						</div>
						<div class="cell-inner" *ngSwitchCase="'rowData'">
							<ngx-json-viewer [json]="log[columnAndLabel.column]" [depth]="0"></ngx-json-viewer>
						</div>
						<div class="cell-inner" *ngSwitchCase="'user'">
							<a class="user-with-email" href="mailto:{{user.email}}" *ngIf="log[columnAndLabel.column] as user">{{user.name}}</a>
						</div>
						<div class="cell-inner" *ngSwitchCase="'action'">
							<ng-container
								*ngIf="
									log.action === 'D' ||
									log.action === 'U' ||
									log.action === 'I'"
								>
								{{actionMapping[log.action]}}
							</ng-container>
						</div>
						<div class="cell-inner" *ngSwitchDefault>
							{{ log[columnAndLabel.column] }}
						</div>
					</div>

				</ng-container>


				<div
					class="row-spacer-and-hoverable"
					[style]='{
						"grid-column-start": isSameTransactionAsPreviousOrNextRow(log, 1) ? firstUniqueColumnHeadingWithinTransaction().column : firstColumnHeading().column,
					}'
					[class]='{
						"is-same-transaction-as-next-row": isSameTransactionAsPreviousOrNextRow(log, 1),
					}'
					*ngIf="data.logs.length > 0">
				</div>
			</div>
		</div>
	</div>


	<div class="actions-bar">
		<div class="disabler-overlay" *ngIf="isLoading"></div>
		<div class="pagination-wrapper">
			<div class="pagination">
				<ng-container>
					<div class="previous-page-button" [class]="{'disabled': visiblePageNumber() <= 1}" (click)="visiblePageNumber() > 1 && goToPage(pageNumber - 1)">
						<div
							class="ficon ficon-ChevronLeftSmall">
						</div>
					</div>
					<div class="page-input-with-action">
						<p-inputNumber type="number" placeholder="Page" class="page-number-input p-inputtext-sm" (keydown.Enter)="goToPage(this.pageNumberInput.value - 1)" [formControl]="pageNumberInput" />
						<div class="go-to-page-button-container">
							<div class="disabler-overlay" *ngIf="!this.pageNumberInput.valid"></div>
							<div pRipple class="button go-to-page" (click)="goToPage(this.pageNumberInput.value - 1)">Go</div>
						</div>
					</div>
					<div class="next-page-button" [class]="{'disabled': visiblePageNumber() >= numberOfPages()}" (click)="visiblePageNumber() < numberOfPages() && goToPage(pageNumber + 1)">
						<div
							class="ficon ficon-ChevronRightSmall">
						</div>
					</div>
					<div class="pagination-info subsection-smallcaps-title">
						<div class="info-item">Page {{visiblePageNumber()}} of {{numberOfPages()}}</div>
						<div class="info-item">{{pageSize}} Event{{pageSize !== 1 ? 's' : ''}} per page</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>
